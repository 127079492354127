
// any CSS you import will output into a single css file (app.css in this case)
import './styles/reve-elite.fr.scss';
window.gtmid = 'GTM-TB4MTF39';
window.fbid = null;
import './app';

const domReady = (fn) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', fn);
    } else {
        fn();
    }
}

(function () {
    domReady(function () {
        const observer_header = new IntersectionObserver(
            function ([e]) {
                document.getElementById('navbar').classList.toggle('opacity-xxl-0', !(e.intersectionRatio < 1 && e.boundingClientRect.y < 0 && e.intersectionRect.y < 1));
            },
            {threshold: [1]}
        );
        observer_header.observe(document.getElementById('second-navbar'));
        // const primary_basket_button = document.getElementById('primary-basket-button');
        // if (primary_basket_button) {
        //     const fixed_basket = document.getElementById('fixed-basket');
        //     if (fixed_basket) {
        //         const observer_basket_button = new IntersectionObserver(
        //             function ([e]) {
        //                 let test = e.intersectionRatio < 1 && e.boundingClientRect.y < 0 && e.intersectionRect.y < 1;
        //                 fixed_basket.classList.toggle('fixed-basket-margin', !test);
        //             },
        //             {
        //                 threshold: [1]
        //             }
        //         );
        //         observer_basket_button.observe(primary_basket_button);
        //     }
        // }
    });
})();
